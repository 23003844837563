<template>
  <div class="row">
    <div class="col-auto">
      <div class="content">
        <div class="row">
          <div class="col">
            <span class="dato" v-if="data.provider == 'paypal'">£{{data.amount}}</span>
            paid with
            <span class="dato">{{data.provider}}</span>
            <span v-if="data.provider == 'stripe'">
              <span v-if="data.data.used_saved_card">
                &nbsp;using a
                <span class="dato">saved card</span>
              </span>
              <span v-else>&nbsp;using a new card</span>
              <span v-if="data.data.save_card">. (card saved)</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  .dato {
    font-weight: bold;
    color: #555;
  }
}
</style>