<template>
  <div class="vave-modal" :class="{'show': showing}">
    <div class="head">
      <div class="row align-items-center justify-content-end">
        <div class="col-auto">
          <div class="ps-4 pale">
            <slot name="title"></slot>
          </div>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <div class="close-icn pe-2 py-1 pale" @click="hide()">
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showing: false,
    };
  },
  methods: {
    hide() {
      this.showing = false;
      this.$emit("closed");
    },
    show() {
      this.showing = true;
    },
    loaded() {},
  },
};
</script>

<style lang="scss" scoped>
.vave-modal {
  background: white;
  border: 1px solid #e6e6e6;
  position: fixed;
  right: 0;
  width: calc(100% - 250px);
  height: 100vh;
  transition: ease 0.4s;
  z-index: 4;
  overflow: hidden;

  // by default have it positioned outside of the screen to the right
  transform: translateX(100%);
  &.show {
    transform: translateX(0%);
    // transition: all cubic-bezier(0.47, 1.22, 0.51, 0.8) 0.4s;
  }
  .head {
    border-bottom: 1px solid #e6e6e6;
    position: sticky;
    top: 0;
    background: white;
    .close-icn {
      cursor: pointer;
    }
  }
  .body {
    overflow: scroll;
    height: 100%;
  }
}
</style>
