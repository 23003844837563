<template>
  <div class="orders-component">
    <modal ref="ordersModal" @closed="unsetOrderId()">
      <template v-slot:title>
        Order Quick View
        <router-link class="blue" target="_blank" :to="'/orders/'+quickViewOrderId">{{quickViewOrderId}}</router-link>
      </template>
      <template v-slot:content>
        <order-details :id="quickViewOrderId" :key="'order-'+quickViewOrderId" v-if="quickViewOrderId" />
      </template>
    </modal>

    <div class="content">
      <div class="filters">
        <div class="row align-items-center">
          <div class="col">
            <input type="text" style="max-width: 300px;" v-model="parameters.q" placeholder="Search orders" class="form-control" v-on:keyup.enter="getOrders()" />
          </div>
          <div class="col-auto pe-0">
            <span class="pale">Order By</span>
          </div>
          <div class="col-auto">
            <select class="form-select" v-model="parameters.sort" @change="logsomething()" style="max-width: 200px;">
              <option value="created_at-desc">Date Recent</option>
              <option value="created_at-asc">Date Oldest</option>
              <option value="amount-desc">Amount Highest</option>
              <option value="amount-asc">Amount Lowest</option>
            </select>
          </div>
          <div class="col-auto">
            <a class="vave-btn" href="#" @click.prevent="showFilters=!showFilters">
              Filters
              &nbsp;
              <i class="fa-regular fa-filter-list"></i>
            </a>
          </div>
        </div>
        <div class="filters-box" :class="{'show': showFilters}">
          <div class="row pale">
            <div class="col-12 mt-2">
              Status
              <multiselect class="override-multiselect-style" v-model="parameters.statuses" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :options="['pending', 'completed', 'processing', 'awaiting_payment_confirmation', 'on_hold', 'cancelled', 'failed']"></multiselect>
            </div>
            <div class="col-12 mt-2">
              Coupon
              <input type="text" class="form-control" v-model="parameters.coupon" style="width: 210px;" />
            </div>
            <div class="col-6 mt-2">
              Payment Provider
              <select class="form-select" v-model="parameters.payment_provider">
                <option value="paypal">Paypal</option>
                <option value="stripe">Stripe</option>
                <option value selected>Any</option>
              </select>
            </div>
            <div class="col-6 mt-2">
              Source
              <select class="form-select" v-model="parameters.utm_source">
                <!-- <option value="leaflet">Leaflet</option>
                <option value="email">Email</option>-->
                <option value="google_shopping">Google Shopping</option>
                <option value="active_campaign">Active Campaign</option>
                <option value="mailchimp">Mailchimp</option>
                <option value selected>Any</option>
              </select>
            </div>
            <div class="col-6 mt-2">
              From
              <input class="form form-control" v-model="parameters.from" type="date" />
            </div>
            <div class="col-6 mt-2">
              To
              <input class="form form-control" v-model="parameters.to" type="date" />
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col mt-3">
              <a href="#" class="blue" @click.prevent="()=>{resetFilters(); showFilters=false;}">Reset</a>
            </div>
            <div class="col-auto mt-3">
              <button class="vave-btn btn-small btn-green" @click="()=>{getOrders(); showFilters=false;}" :disabled="!ordersReady">Apply</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2" v-if="Object.keys(appliedFilters).length">
        <div class="col">
          <div class="filters-applied">
            <div class="label">ACTIVE FILTERS:</div>
            <div class="pill" v-for="filter, key in appliedFilters" :key="'filt-'+key">
              {{key}}: {{filter}}
              <span @click="unsetFilter(key)" class="unset-filter">
                <i class="fas fa-times"></i>
              </span>
            </div>
            <a class="ps-2 blue" href="#" @click.prevent="resetFilters()">Reset</a>
          </div>
        </div>
      </div>

      <div class="row" v-if="!ordersReady">
        <div class="col">
          <div class="mt-4">
            <div class="loading-placeholder">
              <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="table-container mt-2" v-if="ordersReady">
            <table class="table align-middle">
              <thead class="head">
                <tr>
                  <th></th>
                  <th>Order</th>
                  <th>Customer</th>
                  <th class="text-center">Coupon</th>
                  <th class="text-center">UTM</th>
                  <th class="ps-4">Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in ordersList.data" :key="order.id" :class="{'failedToSyncOrder': order.sync_status == 'failed', 'retrying' : order.sync_status == 'retrying', 'isToday': isToday(order)}">
                  <td style="max-width: 60px;">
                    <div class="d-flex flex-column text-center align-items-center">
                      <span v-if="order.status == 'processing' || order.status == 'completed'">
                        <svg class="status-icon green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                        </svg>
                      </span>
                      <span v-else-if="order.status == 'awaiting_payment_confirmation'">
                        <svg class="status-icon yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                        </svg>
                      </span>
                      <span v-else-if="order.status == 'pending'">
                        <svg class="status-icon gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path
                            d="M9.9 232c4.1 0 7.6-3.1 8.1-7.2c5.6-42.9 22.5-82.3 47.6-115c2.5-3.3 2.3-7.9-.6-10.8c-3.3-3.3-8.8-3.1-11.7 .6C26.2 134.7 8 177.1 2.1 223.2c-.6 4.7 3.1 8.8 7.8 8.8zM109.8 65.7c32.7-25.2 72.1-42.1 115-47.6c4.1-.5 7.2-4 7.2-8.1c0-4.7-4.1-8.4-8.8-7.8C177.1 8 134.7 26.2 99.6 53.3c-3.7 2.9-4 8.4-.6 11.7c2.9 2.9 7.6 3.1 10.8 .6zM280 502.1c0 4.7 4.1 8.4 8.8 7.8c46.2-5.9 88.5-24.1 123.6-51.2c3.7-2.9 4-8.4 .6-11.7c-2.9-2.9-7.6-3.1-10.8-.6c-32.7 25.1-72.1 42.1-115 47.6c-4.1 .5-7.2 4-7.2 8.1zm166.3-99.9c-2.5 3.3-2.3 7.9 .6 10.8c3.3 3.3 8.8 3.1 11.7-.6c27.1-35.1 45.3-77.4 51.2-123.6c.6-4.7-3.1-8.8-7.8-8.8c-4.1 0-7.6 3.1-8.1 7.2c-5.6 42.9-22.5 82.3-47.6 115zM99 447c-3.3 3.3-3.1 8.8 .6 11.7c35.1 27.1 77.4 45.3 123.6 51.2c4.7 .6 8.8-3.1 8.8-7.8c0-4.1-3.1-7.6-7.2-8.1c-42.9-5.6-82.3-22.5-115-47.6c-3.3-2.5-7.9-2.3-10.8 .6zM53.3 412.4c2.9 3.7 8.4 4 11.7 .6c2.9-2.9 3.1-7.6 .6-10.8c-25.2-32.7-42.1-72.1-47.6-115c-.5-4.1-4-7.2-8.1-7.2c-4.7 0-8.4 4.1-7.8 8.8C8 334.9 26.2 377.3 53.3 412.4zM413 65c3.3-3.3 3.1-8.8-.6-11.7C377.3 26.2 334.9 8 288.8 2.1c-4.7-.6-8.8 3.1-8.8 7.8c0 4.1 3.1 7.6 7.2 8.1c42.9 5.6 82.3 22.5 115 47.6c3.3 2.5 7.9 2.3 10.8-.6zm33.3 44.8c25.2 32.7 42.1 72.1 47.6 115c.5 4.1 4 7.2 8.1 7.2c4.7 0 8.4-4.1 7.8-8.8c-5.9-46.2-24.1-88.5-51.2-123.6c-2.9-3.7-8.4-4-11.7-.6c-2.9 2.9-3.1 7.6-.6 10.8z"
                          />
                        </svg>
                      </span>
                      <span v-else-if="order.status == 'failed' || order.status == 'cancelled' || order.status == 'on_hold' || order.status == 'refunded'">
                        <svg class="status-icon red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path
                            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                          />
                        </svg>
                      </span>

                      <span class="pale hidden small pt-1" style="width: 80px;">{{order.status}}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <span v-if="order.sync_status == 'succeeded'">
                        <router-link class="bold blue" :to="'/orders/'+order.id">{{order.number}}</router-link>
                      </span>
                      <span class="bold blue" v-else-if="order.sync_status == 'processing'">sync: {{order.sync_status}} ({{order.sync_attempts}} attempts)</span>
                      <span v-else></span>

                      <span class="pale">
                        <span v-if="isToday(order)">Today, {{order.created_at | formattedHour}}</span>
                        <span v-else>{{order.created_at | formattedLongDate}}</span>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <span class="bold blue">{{order.billing_address.first_name}} {{order.billing_address.last_name}}</span>
                      <span class="pale">{{order.email}}</span>
                    </div>
                  </td>
                  <td class="d-none" v-if="order.payment && (order.payment.provider == 'stripe' || order.payment.provider == 'paypal' || order.payment.provider == 'credit' )">
                    <img :src="images[order.payment.provider]" :title="order.payment.provider" height="20px" />
                    <span v-if="order.meta.used_credit_to_pay && order.payment.provider != 'credit'">
                      +
                      <img :src="images['credit']" title="credit" height="20px" />
                    </span>
                  </td>
                  <td v-else class="d-none"></td>

                  <td class="text-center">
                    <span v-if="order.meta.coupons.length">
                      <span class="text-uppercase pale" v-for="coupon,key in order.meta.coupons" :key="key">{{coupon}}</span>
                    </span>
                    <!-- <span v-if="order.payment && (order.payment.provider == 'stripe' || order.payment.provider == 'paypal' || order.payment.provider == 'credit' )">
                    <img :src="images[order.payment.provider]" :title="order.payment.provider" height="20px" />
                    <span v-if="order.meta.used_credit_to_pay && order.payment.provider != 'credit'">
                      +
                      <img :src="images['credit']" title="credit" height="20px" />
                    </span>
                    </span>-->
                  </td>
                  <td v-if="order.meta && order.meta.utm && order.meta.utm.utm_source">
                    <div class="d-flex justify-content-center" style="position:relative;top:3px;">
                      <img v-if="images[order.meta.utm.utm_source.replace(/\s/, '').toLowerCase()]" :src="images[order.meta.utm.utm_source.replace(/\s/, '').toLowerCase()]" :title="order.meta.utm.utm_source" height="20px" />
                      <span v-else>{{order.meta.utm.utm_source}}</span>
                    </div>
                  </td>
                  <td v-else></td>

                  <td>
                    <div class="d-flex flex-column ps-4">
                      <span :class="{'pale':order.status != 'processing' && order.status != 'completed'}">£ {{order.meta.payment_amount.cents | money}}</span>
                      <span class="pale hidden small" v-if="order.payment && (order.payment.provider == 'stripe' || order.payment.provider == 'paypal' || order.payment.provider == 'credit' )">{{order.payment.provider}}</span>
                    </div>
                  </td>
                  <td style="width:130px;">
                    <a class="vave-btn" @click.prevent="showQuickView(order.id)">
                      <div class="d-flex align-items-center">
                        <i class="fa fa-eye me-1"></i>
                        Quick view
                      </div>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination class="paginator" :limit="4" align="center" :data="ordersList" @pagination-change-page="getOrders"></pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="floating-summary" v-if="Object.keys(appliedFilters).length">
      <!-- <div class="pre pale">Filtered:</div> -->
      <div v-if="filteredResults.totalOrders" class="label">
        <span class="value">{{filteredResults.totalOrders}}</span>
        Orders
      </div>
      <div v-if="filteredResults.totalAmount" class="label">
        &nbsp;for a total of
        <span class="value">£{{filteredResults.totalAmount.toLocaleString()}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import moment from "moment";
import pagination from "laravel-vue-pagination";
import Multiselect from "vue-multiselect";
import Modal from "./Modal.vue";
import OrderDetails from "./OrderDetails.vue";
import EventBus from "../bus.js";

export default {
  data() {
    return {
      showFilters: false,
      images: {
        paypal: require("./../assets/paypal_small.png"),
        stripe: require("./../assets/stripe_small.png"),
        credit: require("./../assets/credit_small.png"),
        mstorelimited: require("./../assets/mailchimp.svg"),
        activecampaign: require("./../assets/activecampaign.png"),
        googleshopping: require("./../assets/googleshopping.png"),
        email: require("./../assets/email.png"),
      },
      ordersList: {},
      ordersReady: false,
      filteredResults: {
        totalOrders: null,
        totalAmount: null,
      },
      appliedFilters: {},
      perPage: 15,
      parameters: {
        q: "",
        sort: "created_at-desc",
        coupon: "",
        payment_provider: "",
        utm_source: "",
        from: "",
        to: "",
        statuses: [],
      },
      quickViewOrderId: null,
    };
  },
  components: {
    Modal,
    OrderDetails,
    pagination,
    Multiselect,
  },
  watch: {
    $route() {
      this.getOrders();
    },
  },
  mounted() {
    if (!this.ordersList.data || this.ordersList.data.length <= 0) {
      this.getOrders();
    }
    document.addEventListener("keydown", (event) =>
      this.dismissQuickView(event)
    );
    this.$once("hook:destroyed", () => {
      document.addEventListener("keydown", (event) =>
        this.dismissQuickView(event)
      );
    });
    EventBus.$on("dismiss-modal", () => {
      if (!this.$refs.ordersModal) return;
      setTimeout(() => {
        this.quickViewOrderId = null;
      }, 400);
      this.$refs.ordersModal.hide();
    });
  },
  filters: {
    money(value) {
      return (Math.round(value) / 100).toFixed(2);
    },
    formattedDate(value) {
      return value ? moment(String(value)).format("DD MMMM YY") : "";
    },
    formattedLongDate(value) {
      return value ? moment(String(value)).format("DD MMMM YY, HH:mm") : "";
    },
    formattedHour(value) {
      return value ? moment(String(value)).format("HH:mm") : "";
    },
    diffForHumans(value) {
      return value ? moment(String(value)).fromNow() : "";
    },
    formatStatus(value) {
      return value.replace(/_/g, " ");
    },
  },
  methods: {
    unsetOrderId() {
      setTimeout(() => {
        this.quickViewOrderId = null;
      }, 400);
    },
    dismissQuickView(event) {
      if (event.key === "Escape") {
        if (this.$refs.ordersModal) {
          this.$refs.ordersModal.hide();
        }
        this.unsetOrderId();
      }
    },
    showQuickView(id) {
      if (this.quickViewOrderId == id) {
        this.unsetOrderId();
        this.$refs.ordersModal.hide();
      } else {
        this.quickViewOrderId = id;
        this.$refs.ordersModal.show();
      }
    },
    unsetFilter(key) {
      this.parameters[key] = "";
      this.getOrders();
    },
    isToday(order) {
      return moment(order.created_at).isSame(moment(), "day");
    },
    isPaid(order) {
      return (
        order.status == "completed" ||
        order.status == "processing" ||
        order.status == "cancelled" ||
        order.status == "refunded"
      );
    },
    resetFilters() {
      this.parameters = {
        q: "",
        sort: "created_at-desc",
        // sort_by: "created_at",
        // sort_direction: "desc",
        coupon: "",
        payment_provider: "",
        utm_source: "",
        from: "",
        to: "",
        statuses: [],
      };
      this.appliedFilters = {};
      this.getOrders();
    },
    getOrders(page = 1) {
      this.ordersReady = false;
      this.output = "";
      this.ordersList = [];

      var params = {};
      // filter empty values
      Object.keys(this.parameters).forEach((key) => {
        if (this.parameters[key] != "") {
          // this.parameters[key] is an array, implode by ,
          if (Array.isArray(this.parameters[key])) {
            params[key] = this.parameters[key].join(",");
          } else {
            params[key] = this.parameters[key];
          }
        }
      });

      // return a string containing the filters and query currently applied
      this.appliedFilters = {};
      if (this.parameters.coupon) {
        this.appliedFilters["coupon"] = this.parameters.coupon;
      }
      if (this.parameters.payment_provider) {
        this.appliedFilters["payment_provider"] =
          this.parameters.payment_provider;
      }
      if (this.parameters.utm_source) {
        this.appliedFilters["utm_source"] = this.parameters.utm_source;
      }
      if (this.parameters.from) {
        this.appliedFilters["from"] = this.parameters.from;
      }
      if (this.parameters.to) {
        this.appliedFilters["to"] = this.parameters.to;
      }
      if (this.parameters.statuses.length) {
        this.appliedFilters["statuses"] = this.parameters.statuses.join(", ");
      }
      if (this.parameters.q) {
        this.appliedFilters["q"] = this.parameters.q;
      }

      this.filteredResults = {
        totalOrders: null,
        totalAmount: null,
      };
      params.sort_by = this.parameters.sort.split("-")[0];
      params.sort_direction = this.parameters.sort.split("-")[1];

      axios
        .get(
          "/api/admin/orders/search?page=" + page + "&per_page=" + this.perPage,
          {
            params: params,
          }
        )
        .then((response) => {
          if (response.data.data) {
            this.filteredResults = {
              totalOrders: response.data.meta.total,
              totalAmount: response.data.total_amount,
            };
            this.ordersList = response.data;
          }
        })
        .finally(() => {
          this.ordersReady = true;
        });
    },
    logsomething() {
      console.log("logging");
      this.getOrders();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.orders-component {
  background: #f1f5f9;
  position: relative;

  .content {
    min-height: calc(100vh - 50px);
    .table-container {
      .hidden {
        height: 0;
        overflow: hidden;
        transition: 0.2s;
        opacity: 0;
      }

      // shadow
      tbody tr {
        .status {
          font-size: 0.9em;
        }
        &:hover {
          background: #fafafa;
          &.isToday {
            background: #fafafa;
          }

          .hidden {
            height: 22px;
            transition: 0.2s;
            opacity: 1;
          }
        }
        &.isToday {
          background: #f4fbfc;
          .pale {
            color: #999;
          }
        }
      }
    }
  }
  .ucfirst {
    text-transform: capitalize;
  }
  .failedToSyncOrder {
    background: #dd4f4f8a;
    &:hover {
      background: #ea2a2ab6;
    }
  }
  .retrying {
    background: #daa5498a;
    &:hover {
      background: #eab02ab6;
    }
  }
  .filters-box {
    z-index: 4;
    transition: 0.2s;
    position: absolute;
    right: 20px;
    top: 60px;
    max-width: 500px;
    background: #f1f5f9;
    border: 1px solid #e6e6e6;
    padding: 24px;

    pointer-events: none;
    opacity: 0;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }

  .filters-applied {
    border-radius: 6px;
    padding: 5px 10px;
    display: flex;
    font-size: 14px;
    align-items: center;
    .label {
      color: #888;
      font-weight: 600;
      margin-right: 16px;
    }
    .pill {
      background: #ffc641;
      padding: 3px 10px 1px;
      border-radius: 8px;
      margin-right: 8px;
      font-size: 0.9em;
      // shadow
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
        0 1px 3px 0 rgba(0, 0, 0, 0.19);
      .unset-filter {
        cursor: pointer;
        padding-left: 8px;
      }
    }
  }

  .multiselect__tags {
    border-radius: 0px;
    border: 1px solid #ced4da;
  }

  .floating-summary {
    position: sticky;
    bottom: 0;
    left: 0;
    background: white;
    border: 1px solid #e6e6e6;
    padding: 10px;
    z-index: 3;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    color: #888;
    .pre {
      font-weight: 400;
    }
    .value {
      color: #333;
      font-weight: 600;
    }
  }
}
</style>
