<template>
  <div class="row">
    <div class="col-auto">
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>
