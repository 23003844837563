<template>
  <div class="pt-3">
    <div v-if="!eventsReady">
      <div class="row">
        <div class="col">
          <div class="spinner-border spinner-border-sm ms-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <div class="col-7">
        <div class="events" v-if="Object.keys(events).length > 0">
          <div v-for="group, k in collapsableGroups" :key="k">
            <div class="day">
              <h2>{{k|formatDate}}</h2>
              <div class="group" :ref="'group-' + groupKey" v-for="groupEvents, groupKey in group" :key="'group-' + groupKey" :class="{'multiple': groupEvents.length > 1}">
                <div class="event" :ref="'event-group-'+groupKey" :class="'event-' + getEventTypeByAction(event.event.action)" v-for="event, eventKey in groupEvents" :key="'event-'+eventKey">
                  <div class="row align-items-center">
                    <div class="col" style="max-width: 240px">
                      <div class="action">{{event.event.action | keyed}}</div>
                      <div class="date">{{formatValue(event.event.created_at, 'shortHour')}}</div>
                    </div>
                    <div class="col">
                      <div class="content" v-if="componentExists(componify(event.event.action))">
                        <component :is="componify(event.event.action)" :data="event.event.data" v-if="event.event.data" />
                      </div>
                      <div v-else>{{event.event.data}}</div>
                    </div>
                    <div class="col-auto" v-if="groupEvents.length > 1">
                      <a href="#" class="show-hidden-events-button" @click.prevent="toggleGroup(event.group)">{{groupEvents.length - 1}} hidden events</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="eventsReady">No events for this Order</div>
      </div>
      <div class="col-4">
        <div class="right-column">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h6>STATS</h6>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="events-stats" v-if="Object.keys(events).length > 0">
                    <div class="row">
                      <div class="col-12">Oldest event: {{formattedDate(oldestEvent)}}</div>
                      <div class="col-12">Newest event: {{formattedDate(newestEvent)}}</div>
                      <div class="col-12 mt-3">Session time: {{formatValue(sessionDuration, 'humanInterval')}}</div>
                      <div class="col-12" v-if="totalCheckoutTime> 0">
                        Checkout time:
                        {{formatValue(totalCheckoutTime, 'humanInterval')}}
                      </div>
                      <div class="col-12" v-if="totalCheckoutTime> 0">
                        Browsing time:
                        {{formatValue(totalBrowsingTime, 'humanInterval')}}
                      </div>
                      <div class="col-12 mt-3">Total Events: {{totalEventsCount}}</div>
                      <div class="col-12"># of Events per day</div>
                      <div class="col-12">
                        <div class v-for="dailyEvents, day in events" :key="day">
                          {{formatValue(day, 'date')}}:
                          {{dailyEvents.length}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="cart && Object.keys(cart.items).length">
            <div class="col">
              <div class="row">
                <div class="col">
                  <h5>BASKET</h5>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="cart-items">
                    <div class="cart-item" v-for="item,k in cart.items" :key="k">
                      <div class="row align-items-center">
                        <div class="col-auto">
                          <img :src="image(getProductImage(item), 50, 50)" />
                        </div>
                        <div class="col-auto">
                          <strong>{{item.quantity}}x</strong>
                        </div>
                        <div class="col">
                          {{item.name}}
                          <span class v-if="item.variation_name">- {{item.variation_name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import EventBus from "./../../bus.js";
import MqttClient from "./../../mqtt.js";
import { forEach } from "lodash";
import axios from "../../http.js";
import { formatValue } from "../../formatters";
import Search from "./Search";
import CheckoutCompleted from "./CheckoutCompleted";
import CheckoutTermsAccepted from "./CheckoutTermsAccepted";
import CheckoutStep from "./CheckoutStep";
import Logout from "./Logout";
import CouponAdded from "./CouponAdded";
import VisitPage from "./VisitPage";
import SaveNewAddress from "./SaveNewAddress";
import DeleteAddress from "./DeleteAddress";
import OrderAgain from "./OrderAgain";
import CouponRemoved from "./CouponRemoved";
import StripeCardSelected from "./StripeCardSelected";
import Automation from "./Automation";
import AddToCart from "./AddToCart";
import RemoveFromCart from "./RemoveFromCart";
import OpenBasket from "./OpenBasket";
import InputUpdated from "./InputUpdated";
import OpenBlog from "./OpenBlog";
import OpenBlogArticle from "./OpenBlogArticle";
import SelectedVariation from "./SelectedVariation";
import ViewProduct from "./ViewProduct";
import CheckoutPaymentFailed from "./CheckoutPaymentFailed";
import CheckoutPaymentSucceeded from "./CheckoutPaymentSucceeded";
import FiltersChanged from "./FiltersChanged";
import OrderProducts from "./OrderProducts";
import moment from "moment";
import imageProxy from "./../../imageProxy";

export default {
  props: ["stickyId"],
  data() {
    return {
      subscribedToCartTopic: false,
      subscribedToEventsTopic: false,
      eventTypes: {
        coupon_added: "2",
        coupon_removed: "2",
        open_basket: "4",
        remove_from_cart: "4",
        add_to_cart: "6",
        view_product: "3",
        selected_variation: "7",
        filters_changed: "4",
        open_blog: "4",
        open_blog_article: "4",
        checkout_step: "8",
        checkout_terms_accepted: "8",
        checkout_completed: "1",
        checkout_payment_succeeded: "8",
        checkout_payment_failed: "8",
        search: "9",
        visit_page: "4",
        order_again: "10",
        automation: "10",
      },
      eventsReady: false,
      events: [],
      cartReady: false,
      cart: null,
      collapsableGroup: 0,
      collapsableGroups: {},
    };
  },
  computed: {
    totalCheckoutTime() {
      var start = null;
      var end = null;
      for (let index = 0; index < Object.keys(this.events).length; index++) {
        let day = Object.keys(this.events)[index];
        for (let j = 0; j < this.events[day].length; j++) {
          let event = this.events[day][j];
          if (event.action == "checkout_completed") {
            end = moment(event.created_at);
          }
          if (
            event.action == "checkout_step" &&
            event.data.checkout_step == 1
          ) {
            start = moment(event.created_at);
            return end ? end.diff(start, "seconds") : 0;
          }
        }
      }
      return 0;
    },
    totalBrowsingTime() {
      return this.sessionDuration - this.totalCheckoutTime;
    },
    totalEventsCount() {
      var count = 0;
      forEach(this.events, (events) => {
        forEach(events, () => {
          count++;
        });
      });
      return count;
    },
    oldestEvent() {
      var oldestEvent = moment();
      forEach(this.events, (events) => {
        forEach(events, (event) => {
          if (moment(event.created_at).isBefore(oldestEvent)) {
            oldestEvent = moment(event.created_at);
          }
        });
      });
      return oldestEvent;
    },
    newestEvent() {
      var newestDay = this.oldestEvent;
      forEach(this.events, (events) => {
        forEach(events, (event) => {
          if (newestDay.isBefore(moment(event.created_at))) {
            newestDay = moment(event.created_at);
          }
        });
      });
      return newestDay;
    },
    sessionDuration() {
      return this.newestEvent.diff(this.oldestEvent, "seconds");
    },
  },
  components: {
    Automation,
    Search,
    Logout,
    CheckoutCompleted,
    CheckoutTermsAccepted,
    CheckoutStep,
    CouponAdded,
    CouponRemoved,
    StripeCardSelected,
    OpenBasket,
    AddToCart,
    RemoveFromCart,
    SelectedVariation,
    ViewProduct,
    OpenBlog,
    OpenBlogArticle,
    CheckoutPaymentFailed,
    CheckoutPaymentSucceeded,
    FiltersChanged,
    VisitPage,
    OrderAgain,
    SaveNewAddress,
    DeleteAddress,
    InputUpdated,
    OrderProducts,
  },
  mounted() {
    this.getOrderEvents();
    this.getCart();
  },
  filters: {
    keyed(value) {
      return value.replace(/_/g, " ").trim();
    },
    formatDate(value) {
      if (value) {
        return moment(String(value), ["YYYY-MM-DD"]).format(
          "dddd Do MMMM YYYY"
        );
      }
    },
  },
  methods: {
    formatValue,
    image: imageProxy.image,
    formattedDate(date) {
      return date.format("DD/MM/Y HH:mm");
    },
    differenceForHumans(date1, date2) {
      let duration = moment.duration(date2.diff(date1));
      return duration.humanize();
    },
    loadedComponents() {
      var loaded = [];
      var components = this.$options.components;
      for (var key in components) {
        loaded.push(key);
      }
      return loaded;
    },
    getProductImage(item) {
      let p = this.$store.state.products.filter((product) => {
        return product.name == item.name;
      });
      if (p[0] && p[0].images) {
        return p[0].images[0].origin_src;
      }
      return "";
    },
    componentExists(component) {
      var components = this.loadedComponents.call(this);
      if (components.indexOf(component) !== -1) {
        return true;
      }
      return false;
    },
    componify(action) {
      // If the action name starts with "visit_", return the visit component
      if (action.startsWith("visit_")) {
        return "VisitPage";
      }
      return action
        .replace(/_/g, " ")
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word) {
          return word.toUpperCase();
        })
        .replace(/\s+/g, "");
    },
    getEventTypeByAction(action) {
      var index = "4";
      forEach(this.eventTypes, (i, type) => {
        if (type == action) {
          index = i;
        }
      });
      return index;
    },
    getOrderEvents() {
      axios.get("/api/admin/stickies/" + this.stickyId + "/events").then(
        (response) => {
          if (response.data.data) {
            this.events = response.data.data;
            this.buildCollapsableGroups();
            this.eventsReady = true;
            if (!this.subscribedToEventsTopic) {
              let topic = "globus/vaveproxy/events/#";
              MqttClient.subscribe(topic);
              EventBus.$on(topic, this.getOrderEvents);
              this.subscribedToEventsTopic = true;
            }
          }
        },
        (response) => {
          if (response.response.data && response.response.data.error) {
            this.events.error = response.response.data.error;
            this.eventsReady = true;
            return;
          }
          this.events.error = "Could not load events data";
          this.eventsReady = true;
        }
      );
    },
    getCart() {
      var that = this;
      axios
        .get("/api/admin/stickies/" + this.stickyId + "/cart")
        .then((response) => {
          if (response.data.data) {
            this.cart = response.data.data;
            this.cartReady = true;
            if (!that.subscribedToCartTopic) {
              let topic = "globus/vaveproxy/carts/" + this.cart.id + "/updated";
              MqttClient.subscribe(topic);
              EventBus.$on(topic, this.getCart);
              this.subscribedToCartTopic = true;
            }
          }
        });
    },
    buildCollapsableGroups() {
      var tmp = {};
      forEach(this.events, (events, day) => {
        tmp[day] = [];

        forEach(events, (event, key) => {
          let group = this.getEventGroup(events, key);

          tmp[day].push({
            group: group,
            originalKey: key,
            shouldShow: this.shouldShow(events, key),
            event: event,
          });
        });
      });
      this.collapsableGroups = tmp;

      // Group the events by day and group
      tmp = {};
      forEach(this.collapsableGroups, (events, day) => {
        tmp[day] = {};
        forEach(events, (event) => {
          if (!tmp[day][event.group]) {
            tmp[day][event.group] = [];
          }
          tmp[day][event.group].push(event);
        });
      });
      this.collapsableGroups = tmp;
    },
    shouldShow(day, eventKey) {
      let currentAction = day[eventKey].action;
      if (eventKey == 0) {
        return true;
      }
      let collapsableEvents = [
        "visit_page",
        "input_updated",
        "stripe_card_selected",
      ];
      if (!collapsableEvents.includes(currentAction)) {
        return true;
      }
      if (currentAction !== day[eventKey - 1].action) {
        return true;
      }
      return false;
    },
    getEventGroup(day, eventKey) {
      if (this.shouldShow(day, eventKey)) {
        this.collapsableGroup++;
      }
      return this.collapsableGroup;
    },
    toggleGroup(group) {
      this.$refs["group-" + group][0].classList.toggle("expanded");
      var w = this.$refs["event-group-" + group];
      forEach(w, (el) => {
        el.classList.toggle("visible");
      });
    },
  },
  beforeDestroy() {
    EventBus.$off("globus/vaveproxy/events/#", this.getOrderEvents);
    EventBus.$off(
      "globus/vaveproxy/carts/" + this.cart.id + "/updated",
      this.getCart
    );
  },
};
</script>

<style lang="scss" scoped>
.right-column {
  margin-top: 68px;
  h6 {
    margin-bottom: 10px;
  }

  .events-stats {
    background: rgb(245, 245, 245);
    border-radius: 14px;
    color: #666;
    padding: 10px;
    margin-bottom: 30px;
  }

  .cart-items {
    background: rgb(245, 245, 245);
    border-radius: 14px;
    color: #666;
    .cart-item {
      padding: 10px;
      img {
        border-radius: 50%;
        padding: 2px;
        background: white;
        filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.2));
      }
    }
  }
}

.group {
  &.multiple {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: -45px;
      background: white;
      z-index: 4;
      height: 10px;
      width: 30px;
      transform: rotate(45deg);
      transition: opacity 250ms ease-in-out;
    }
    &.expanded {
      &:before {
        opacity: 0;
      }
    }
    .event {
      &:not(:first-child) {
        transition: all 250ms ease-in-out !important;
        max-height: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
        pointer-events: none;
        .show-hidden-events-button {
          display: none;
        }
        &.visible {
          margin: 10px;
          max-height: 200px;
          padding: 10px;
          overflow-y: auto;
        }
      }
    }
  }
}

.events {
  width: 90%;
  margin: 20px auto;
  position: relative;
  height: 100%;

  // left line
  &::after {
    content: "";
    display: block;
    width: 5px;
    height: 98%;
    background: #cacaca;
    position: absolute;
    top: 0;
    left: -35.5px;
    z-index: 1;
    border-radius: 2.5px;
  }

  .event {
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    margin: 30px 0px;
    position: relative;
    background: rgb(245, 245, 245);

    transition: background-color 600ms ease-in-out !important;
    &.reveal {
      display: block;
      background-color: rgb(140, 227, 140);
    }

    border-radius: 10px;
    z-index: 4;
    .action {
      text-transform: capitalize;
      font-weight: bold;
    }
    .date {
      font-size: 15px;
      color: #999;
    }
    &::after {
      content: "";
      display: block;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 4px solid white;
      position: absolute;
      top: calc(50% - 14px);
      left: -47px;
      z-index: 2;
      background: #cacaca;
    }
    &.event-1 {
      &::after {
        color: white;
        text-align: center;
        content: "!";
        vertical-align: -50%;
        padding: 4px 0px 0 0;
        width: 40px;
        height: 40px;
        background: #4daa57;
        left: -52px;
      }
    }
    &.event-2 {
      &::after {
        content: "";
        background: #f1c40f;
      }
    }
    &.event-3 {
      &::after {
        content: "";
        background: #3498db;
      }
    }
    &.event-4 {
      &::after {
        content: "";
        background: #95a5a6;
      }
    }
    &.event-5 {
      &::after {
        content: "";
        background: #4daa57;
      }
    }
    &.event-6 {
      &::after {
        content: "";
        background: #e67e22;
      }
    }
    &.event-7 {
      &::after {
        content: "";
        background: #5fbaf6;
      }
    }
    &.event-8 {
      &::after {
        content: "";
        background: #16a085;
      }
    }
    &.event-9 {
      &::after {
        content: "";
        background: #a29bfe;
      }
    }
    &.event-10 {
      &::after {
        content: "";
        background: #da4d11;
      }
    }
  }
  .day {
    color: #333;
    font-weight: 600;
    position: relative;
    h2 {
      margin-left: 15px;
      background: #2c3d50;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 50px;
      display: inline-block;
      font-size: 1em;
      color: #fff;
      z-index: 3;
      position: relative;
      margin-top: 7px;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 150px;
      position: absolute;
      top: -80px;
      left: -50px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 10px;
      z-index: 2;
    }
    &::after {
      content: "";
      display: block;
      width: 100px;
      height: 50px;
      border-top: 5px solid #cacacaed;
      border-left: 5px solid #cacaca;
      border-radius: 50px 0 0 0;
      position: absolute;
      top: 23px;
      left: -35.5px;
      z-index: 2;
    }
  }

  .show-hidden-events-button {
    font-size: 0.8em;
    color: #999;
  }
}
</style>