<template>
  <div class="row">
    <div class="col-auto">
      <div class="content">
        <div class="row">
          <div class="col">
            <div class="row invalid">
              <div class="col-auto">
                <i class="fa-solid fa-circle-exclamation"></i>
                &nbsp;Payment failed with {{data.provider}}:
              </div>
            </div>
            <div class="col">
              <span class>{{data.message}}</span>
              <span class>{{data.error ?? ''}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  .dato {
    font-weight: bold;
    color: #555;
  }
  .invalid {
    color: rgb(224, 29, 29);
  }
}
</style>