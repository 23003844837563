<template>
  <div class="content">
    <div class="row">
      <div v-for="i,k in filters" :key="k">
        <div class="col">
          <span>{{k}}</span>:
          <span v-for="filter, filterKey in i" :key="filterKey">
            <span v-if="k == 'categories' || k == 'subcategories'">{{getCategoryById(filter)}}</span>
            <span v-else>{{filter}}</span>
            <span v-if="filterKey < Object.keys(i).length -1">,&nbsp;</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  computed: {
    filters() {
      var ffs = this.data.filters;
      return Object.keys(ffs)
        .filter(function (k) {
          // Remove the price [0,50], as it's the standard
          if (k == "price" && ffs[k][0] == 0 && ffs[k][1] == 50) {
            return false;
          }
          // Remove the empty filters
          return ffs[k].length;
        })
        .reduce((acc, k) => {
          acc[k] = ffs[k];
          return acc;
        }, {});
    },
  },
  methods: {
    getCategoryById(selectedId) {
      if (!this.$store.state.categoriesReady) {
        return "loading categories...";
      }
      let cat = this.$store.state.categories.filter(function (c) {
        return c.id == selectedId;
      });
      return cat[0] ? cat[0].name : selectedId;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  .dato {
    font-weight: bold;
    color: #555;
  }
}
</style>