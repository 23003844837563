<template>
  <div class="row">
    <div class="col">
      <div class="content">
        total amount of
        <span class="dato">£{{data.total}}</span> <span v-if="data.shipping > 0">+ shipping
        <span class="dato">£{{data.shipping}}</span></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  .dato {
    font-weight: bold;
    color: #555;
  }
}
</style>