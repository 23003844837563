<template>
  <div class="row">
    <div class="col">
      <div class="content">
        <router-link :to="'/orders/'+data.order_id" target="_blank">
          Order {{data.order_id}}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  a{
    color: #666;
  }
}
</style>