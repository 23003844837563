<template>
  <div class="row">
    <div class="col-auto">
      <div class="content">
        <div class="row">
          <div class="col">
            pm_id hash:
            <br />
            <span class="dato">{{data.card}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  .dato {
    font-weight: bold;
    color: #555;
  }
}
</style>