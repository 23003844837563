<template>
  <div class="row">
    <div class="col">
      <div class="content">
        <div v-for="v,k in data.address" :key="k">{{k}}: {{v}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  .dato {
    font-weight: bold;
    color: #555;
  }
}
</style>