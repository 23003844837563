<template>
  <div class="row">
    <div class="col">
      <div class="content">
        {{data.page}}
        <div v-if="data.meta.id">
          <router-link v-if="data.page == 'Order details'" :to="'/orders/'+data.meta.id" target="_blank">Order {{data.meta.id}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  a {
    color: #666;
  }
}
</style>