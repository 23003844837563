<template>
  <div class="row">
    <div class="col-auto">
      <div class="content">
        <div v-if="data.coupon_exists">
          <span class="coupon">{{data.coupon}}</span>
        </div>
        <div v-if="!data.coupon_exists" class="invalid">
          <i class="fa-solid fa-circle-exclamation"></i>
          &nbsp;Invalid coupon:
          <span class="coupon">{{data.coupon}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  .coupon {
    font-weight: bold;
    color: #555;
    text-transform: uppercase;
  }
  .invalid{
    color:rgb(224, 29, 29);
  }
}
</style>