<template>
  <div class="row">
    <div class="col-auto">
      <div class="content">
        by
        <span class="dato">{{data.order_by.replace(/_/g, " ")}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.content {
  color: #666;
  font-weight: 300;
  font-size: 0.9em;
  .dato {
    font-weight: bold;
    color: #555;
  }
}
</style>